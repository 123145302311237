<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>

    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <h4 class="title">Pengajuan Purchases Order</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-barang">Barang </router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/data-barang/detail">Edit</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Invoice
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>
        <div class="card info-detail">
          <div class="row">
            <div class="col-12">
              <div class="title">Pengajuan Purchases Order</div>
              <div class="sub-title">Edit informasi yang diperlukan</div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <div class="payment-invoice">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="title-invoice">Finanta</div>
                  </div>
                  <div
                    class="col-md-6 text-right address-company d-flex justify-content-end flex-wrap"
                  >
                    <div style="max-width: 280px">
                      Jl. Nitikan Baru No.37, Sorosutan, UMBULHARJO, KOTA
                      YOGYAKARTA, DI Yogyakarta
                    </div>
                  </div>
                </div>
                <hr />
                <div class="card-detail-invoice">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="text">Invoice #AUTO_GENERATE</div>
                      <div class="text">
                        <span>Tanggal : {{ formatDate(formData.date) }}</span
                        >&nbsp;
                        <div class="d-inline-block">
                          <div
                            class="d-inline-flex align-items-center"
                            style="cursor: pointer"
                            title="klik for edit due date"
                            @click="showPickerDate"
                          >
                            <img src="/img/edit-white.svg" alt="edit" />
                          </div>
                        </div>
                      </div>
                      <div class="text position-relative">
                        <span
                          >Due Date : {{ formatDate(formData.due_date) }}</span
                        >&nbsp;
                        <div class="d-inline-block">
                          <div
                            class="d-inline-flex align-items-center"
                            style="cursor: pointer"
                            title="klik for edit due date"
                            @click="showPicker"
                          >
                            <img src="/img/edit-white.svg" alt="edit" />
                          </div>
                        </div>

                        <Calendar
                          :attributes="attrsDate()"
                          style="
                            position: absolute;
                            z-index: 1;
                            top: 20px;
                            left: 0;
                          "
                          @dayclick="onDayClickDate($event)"
                          v-click-outside="showPickerDate"
                          :masks="masks"
                          v-if="showCallendarDate"
                          class="datePicker"
                        ></Calendar>
                        <Calendar
                          :attributes="attrs()"
                          :min-date="new Date()"
                          style="
                            position: absolute;
                            z-index: 1;
                            top: 20px;
                            left: 0;
                          "
                          @dayclick="onDayClick($event)"
                          v-click-outside="showPicker"
                          :masks="masks"
                          v-if="showCallendar"
                          class="datePicker"
                        ></Calendar>
                      </div>

                      <div class="text">Status : Aktif</div>
                    </div>
                    <div
                      class="col-md-6 text-right d-flex justify-content-end flex-wrap"
                    >
                      <div class="text w-100">Dikirim untuk</div>
                      <div class="text w-100">Rajawali</div>
                      <div class="text" style="max-width: 220px">
                        Jl. Sorogenen Jl. Nitikan Baru No.27B, Sorosutan,
                        UMBULHARJO, KOTA YOGYAKARTA, DI Yogyakarta
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Item Detail</div>
                    <div class="sub-title">Masukan item yang ingin dibayar</div>
                  </div>
                </div>
                <div class="row mt-4 detail-company">
                  <div class="col-md-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="width: 20%">Nama Item</th>
                          <th style="width: 20%" class="text-right">Nilai</th>
                          <th style="width: 10%">QTY</th>
                          <!-- <th style="width:8%;">Pajak</th> -->
                          <th style="width: 20%" class="text-right">Jumlah</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(value, index) in formDetail"
                          :key="index"
                          style="border-bottom: 1.5px solid #eceff2"
                        >
                          <td
                            style="
                              padding: 20px 20px 20px 0px !important;
                              border-color: #eceff1;
                            "
                          >
                            <input
                              type="text"
                              class="form-control"
                              v-model="formDetail[index].nama"
                              placeholder="Nama Item"
                              style="
                                height: 47px;
                                padding: 0px 24px;
                                font-size: 14px;
                                box-shadow: none;
                              "
                            />
                          </td>
                          <td
                            style="
                              padding: 20px 20px 20px 0px !important;
                              border-color: #eceff1;
                            "
                          >
                            <input
                              type="text"
                              class="form-control text-right"
                              v-money="configMoney"
                              @keyup="countRow(index)"
                              v-model.lazy="formDetail[index].nilai"
                              placeholder="Nilai"
                              style="
                                height: 47px;
                                padding: 0px 24px;
                                font-size: 14px;
                                box-shadow: none;
                              "
                            />
                          </td>
                          <td
                            style="
                              padding: 20px 20px 20px 0px !important;
                              border-color: #eceff1;
                            "
                          >
                            <input
                              type="text"
                              class="form-control text-center"
                              v-maska="'#######'"
                              @keyup="countRow(index)"
                              v-model="formDetail[index].qty"
                              placeholder="QTY"
                              style="
                                height: 47px;
                                padding: 0px 24px;
                                font-size: 14px;
                                box-shadow: none;
                              "
                            />
                          </td>
                          <!-- <td>
                                                            <div class="input-group flex-nowrap">
                                                                <input type="text" class="form-control text-center" v-maska="'##'" @keyup="countRow(index)" v-model="formDetail[index].pajak" placeholder="Pajak" style="height: 47px; padding: 0px 24px; font-size: 14px; box-shadow: none;">
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text" style="height: 47px; padding: 0 10px">%</span>
                                                                </div>
                                                            </div>
                                                        </td> -->
                          <td
                            style="
                              padding: 20px 20px 20px 0px !important;
                              border-color: #eceff1;
                            "
                          >
                            <input
                              type="text"
                              class="form-control text-right"
                              v-money="configMoney"
                              readonly
                              v-model.lazy="formDetail[index].sub_total"
                              placeholder="Jumlah"
                              style="
                                height: 47px;
                                padding: 0px 24px;
                                font-size: 14px;
                                box-shadow: none;
                              "
                            />
                            <div
                              style="
                                position: absolute;
                                right: -30px;
                                top: 50%;
                                transform: translateY(-50%);
                              "
                            >
                              <img
                                src="/img/avatar/icon-plus.svg"
                                alt="img"
                                @click="addRow"
                                v-if="index === formDetail.length - 1"
                                class="btn-add-row"
                                style="cursor: pointer"
                              />
                              <img
                                src="/img/avatar/icon-delete.svg"
                                v-if="formDetail.length > 1"
                                @click="deleteRow(index)"
                                class="btn-add-row ml-1"
                                style="cursor: pointer; width: 20px"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="2">&nbsp;</td>
                          <td
                            style="
                              padding-left: 0px !important;
                              padding-right: 20px !important;
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="mr-2">Pajak</div>
                              <div class="input-group flex-nowrap">
                                <input
                                  type="text"
                                  class="form-control text-center"
                                  @keyup="countAll()"
                                  v-maska="'##'"
                                  v-model="formData.pajak"
                                  placeholder="Pajak"
                                  style="
                                    height: 47px;
                                    padding: 0px 24px;
                                    font-size: 14px;
                                    box-shadow: none;
                                  "
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    style="height: 47px; padding: 0 10px"
                                    >%</span
                                  >
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <div class="amount font-weight-normal">Rp.</div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">&nbsp;</td>
                          <td
                            style="
                              padding-left: 0 !important;
                              border-top: 1.5px solid #eceff2;
                            "
                          >
                            Total
                          </td>
                          <td
                            style="border-top: 1.5px solid #eceff2"
                            class="text-right"
                          >
                            <div class="amount font-weight-normal">Rp.</div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';
// import Datepicker from 'vue3-date-time-picker';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import {
//   get_PiutangPreInvoice,
//   post_PiutangSaveInvoice,
// } from "../../../../actions/piutang";
import {
  Calendar,
  // DatePicker
} from "v-calendar";

import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { maska } from "maska";
import vClickOutside from "click-outside-vue3";
// import { showAlert } from "../../../../helper";
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    Calendar,
  },

  directives: {
    clickOutside: vClickOutside.directive,
    money: VMoney,
    maska,
  },

  mounted() {},

  data() {
    return {
      id: this.$route.params.id,
      kode_project: this.$route.params.kode_project
        ? this.$route.params.kode_project
        : "",
      preInvoice: {
        alamat_company: "",
        client: "",
        alamat_client: "",
      },
      formData: {
        id_piutang: this.$route.params.id,
        tanggal: moment().format("YYYY-MM-DD"),
        due_date: moment().add(7, "days").format("YYYY-MM-DD"),
        nilai: 0,
        status: "aktif",
        status_pembayaran: "unpaid",
        pajak: "",
        nilai_pajak: "0",
        date: moment().format("YYYY-MM-DD"),
      },
      showCallendar: false,
      showCallendarDate: false,
      formDetail: [
        {
          nama: "",
          qty: "",
          nilai: 0,
          pajak: "",
          sub_total: 0,
        },
      ],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
    };
  },

  //   created() {
  //     this.getPreInvoice();
  //   },

  methods: {
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    showPicker() {
      this.showCallendar = !this.showCallendar;
    },

    showPickerDate() {
      this.showCallendarDate = !this.showCallendarDate;
    },

    attrs() {
      return [
        {
          highlight: true,
          dates: this.formData.due_date,
        },
      ];
    },

    attrsDate() {
      return [
        {
          highlight: true,
          dates: this.formData.date,
        },
      ];
    },

    addRow() {
      console.log("asdasd");
      this.formDetail.push({
        nama: "",
        qty: "",
        nilai: 0,
        pajak: "",
        sub_total: 0,
      });
    },

    onDayClick(ev) {
      this.formData.due_date = moment(ev.date).format("YYYY-MM-DD");
      this.showPicker();
    },

    onDayClickDate(ev) {
      console.log("Asdsad");
      this.formData.date = moment(ev.date).format("YYYY-MM-DD");
      this.showPickerDate();
    },
    countRow(index) {
      var row = this.formDetail[index];
      var qty = row.qty ? parseInt(row.qty.toString().replace(/\./g, "")) : 0;
      var rate = row.nilai
        ? parseInt(row.nilai.toString().replace(/\./g, ""))
        : 0;
      var pajak = row.pajak
        ? parseInt(row.pajak.toString().replace(/\./g, ""))
        : 0;
      var sub_total = qty * rate;
      if (pajak) {
        var plus = Math.round((sub_total * pajak) / 100);
        sub_total += plus;
      }
      this.formDetail[index].sub_total = sub_total;
      this.countAll();
    },
    deleteRow(index) {
      this.formDetail.splice(index, 1);
      this.countAll();
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.table-bordered td,
.table-bordered th {
  border: 1px solid #eceff1;
}

table {
  border-right: none;
  border-left: none;
}

.table th {
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #eceff1 !important;
}

/* table>tbody>tr:last-child>td:last-child>img {
        opacity: 0;
        transition: opacity .2s ease-out;
        transition-delay: .2s;
    } */

table > tbody > tr > td {
  position: relative;
}

/* table>tbody>tr:last-child:hover>td:last-child>img {
        opacity: 1;
        transition-delay: 0s;
    } */

.table td {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border: none;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

/* Invoice */

table > tbody > tr:last-child > td:last-child {
  position: relative;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 25px;
}

.name-client {
  font-size: 13px;
  font-weight: 500;
  color: #263238;
}

.email-client {
  font-size: 10px;
  font-weight: 400;
  color: #898989;
  margin-top: 5px;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 30px 47px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.text {
  /* margin-top: 10px; */
  font-size: 12px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

/* .payment-invoice svg {
        width: 30px;
        height: 30px;
    } */

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.payment-invoice .title {
  font-size: 15px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

/* end Invoice */
</style>
